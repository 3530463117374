import { useContext } from 'react';

import { DocumentContext } from '../../../../../../reducers/Document/documentReducer';
import SignatureField from './SignatureField';
import CheckboxField from './CheckboxField';
import CheckboxGroup from './CheckboxGroup';
import TextField from './TextField';

export default function Fields() {
  const { state, dispatch } = useContext(DocumentContext);
  const { displayOptions, document } = state;

  const {
    signatureFields,
    textBoxFields,
    checkboxFields,
    checkboxGroupFields,
    workflowId,
  } = document;

  const {
    page,
    zoom,
    areFieldsEditable,
  } = displayOptions;

  const filterField = (field) => {
    const { position } = field;
    const { pageNumber } = position;
    return pageNumber === page;
  };

  return (
    <>
      {areFieldsEditable && textBoxFields
        .filter(filterField)
        .map(field => (
          <TextField field={field} dispatch={dispatch} zoom={zoom} />
        ))}
      {areFieldsEditable && checkboxFields
        .filter(filterField)
        .map(field => (
          <CheckboxField field={field} dispatch={dispatch} zoom={zoom} />
        ))}
       {areFieldsEditable && checkboxGroupFields
        .map(group => (
          <CheckboxGroup
            group={group}
            filter={filterField}
            dispatch={dispatch}
            zoom={zoom}
          />
        ))}
      {signatureFields
        .filter(filterField)
        .filter(f => !f.signed)
        .map(field => (
          <SignatureField
            field={field}
            dispatch={dispatch}
            zoom={zoom}
            workflowId={workflowId}
            areFieldsEditable={areFieldsEditable}
            checkboxFields={checkboxFields}
            checkboxGroups={checkboxGroupFields}
            textFields={textBoxFields}
          />
        ))}
    </>
  );
}
