import { useContext } from 'react';
import { DocumentContext } from '../../../../../reducers/Document/documentReducer';

import Fields from './Fields/Fields';
import SignatureArea from './SignatureArea';

const styles = {
  container: { width: '100%', overflowX: 'auto', overflowY: 'hidden' },
  imageContainer: {
    position: 'relative',
    margin: '0px auto',
  },
  imageOverlay: {
    backgroundColor: '#989c99',
    opacity: 0.5,
    position: 'absolute',
    overflow: 'visible',
    zIndex: 1010,
    left: 0,
    top: 0,
  }
}

export default function ImageView(props) {
  const { state } = useContext(DocumentContext);

  const { displayOptions, document } = state;
  const {
    page,
    zoom,
    currentSignature,
    image,
  } = displayOptions;

  const {
    pageSizes,
  } = document;

  const width = pageSizes[page - 1][1].width * zoom;
  const height = pageSizes[page - 1][1].height * zoom;

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.imageContainer,
          width: width,
          height: height,
        }}
      >
        <div style={{
          ...styles.imageOverlay,
          visibility: currentSignature === null ? 'hidden' : 'visible',
          width: width,
          height: height,
        }} />
        <div>
          <img
            style={{ width: width, height: height }}
            src={`data:image/png;base64,${image}`}
            alt="Document"
          />
        </div>
        {currentSignature === null && (
          <Fields />
        )}
        {currentSignature && (
          <SignatureArea/>
        )}
      </div>
    </div>
  );
}
