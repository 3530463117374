import { Container, Grid } from 'semantic-ui-react';

import NavigationButtons from './NavigationButtons';

export default function TopMenuBar() {
  return (
    <Container>
      <Grid centered>
        <Grid.Row>
          <NavigationButtons />
        </Grid.Row>
      </Grid>
    </Container>
  );
}
