import { Input } from 'semantic-ui-react';

export default function TextField(props) {
  const { field, zoom, dispatch } = props;
  const { position, fieldId, value } = field;

  const onChange = (_evt, data) => {
    dispatch({
      type: 'setTextFieldValue',
      fieldId: fieldId,
      value: data.value,
    });
  }

  return (
    <Input
      fieldId={fieldId}
      style={{
        position: 'absolute',
        overflow: 'visible',
        zIndex: 1000,
        left: position.x * zoom,
        top: position.y * zoom,
        width: position.width * zoom,
        height: position.height * zoom,
        fontSize: `${12 * zoom}px`,
      }}
      value={value}
      onChange={onChange}
    />
  );
}
