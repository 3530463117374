import { useContext, useState } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';

import { StartContext } from '../../../../reducers/Start/startReducer';
import { secureFetch } from '../../../../libs/utils';
import { useDidMountEffect } from '../../../../libs/hooks';
import certAuthSessionService from '../../../../services/certAuthSession';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const style = {
  header: {
    margin: '1em 0em'
  },
  caption: {
    fontSize: '1.25em',
    fontWeight: 'bold',
  }
}

export default function DocumentList() {
  const { dispatch } = useContext(StartContext);
  const [documents, setDocuments] = useState([]);

  const getSessions = async () => {
    await secureFetch(
      () => certAuthSessionService.getSessions(),
      (response) => {
        setDocuments(response.sessions);
      },
      (error) => dispatch({
        type: 'setErrorMessage',
        error,
      }),
      'Nem sikerült betölteni a dokumentumokat'
    );
  }

  const getDisplayInfo = (doc) => {
    const displayInfo = doc
      .metaData
      .find(meta => meta.key.toLowerCase() === 'displayinfo');

      return displayInfo !== undefined ? displayInfo.value : 'Dokumentum';
  }

  const openDocument = (workflowId) => {
    window.location.replace(`${window.location.href}?workflowId=${workflowId}`);
  }

  useDidMountEffect(getSessions);

  return (
    <Grid centered relaxed>
      {documents.length === 0 && (
        <Grid.Row>
          <Header as="h2" style={style.header}>
            <FormattedMessage {...messages.noDocumentsToSign} />
          </Header>
        </Grid.Row>
      )}
      {documents.map(doc =>
        (
          <Grid.Row>
            <span stlye={style.caption}>
              {getDisplayInfo(doc)}
            </span>
            <Button
              onClick={() => openDocument(doc.workflowId)}
            >
              <FormattedMessage {...messages.sign} />
            </Button>
          </Grid.Row>
        )
      )}
      <Grid.Row>
        <Button onClick={() => getSessions()}>Frissít</Button>
      </Grid.Row>
    </Grid>
  );
}
