import { useContext } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import ActionButtons from './ActionButtons';
import NavigationButtons from './NavigationButtons';

import { DocumentContext } from '../../../../../reducers/Document/documentReducer';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

export default function FloatingMenuBar() {
  const { state } = useContext(DocumentContext);
  const { document } = state;
  const { signatureFields } = document;

  const unsignedFields = signatureFields.filter(f => !f.signed).length;

  return (
    <>
      <Container style={{height: '3rem'}} />
      <Container style={{
        position: 'fixed',
        zIndex: '100',
        top: '0.5em',
        left: '0',
        right: '0',
      }}>
        <Grid
          columns={2}
        >
          <Grid.Column style={{textAlign: 'center'}}>
            <NavigationButtons/>
            <span style={{margin: '1em'}}>
              <FormattedMessage
                {...messages.signatureFields}
                values={{
                  unsignedFields
                }}
              />
            </span>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <ActionButtons/>
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}
