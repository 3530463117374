import { useReducer, useContext } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import ErrorMessage from '../../../components/ErrorMessage';
import { ConfigContext } from '../../../reducers/configContext';
import {
  StartContext,
  startReducer
} from '../../../reducers/Start/startReducer';

import SampleDocuments from './SampleDocuments';
import DocumentList from './DocumentList';
import messages from '../messages';

const style = {
  header: {
    marginTop: '3em',
    marginBottom: '2em',
  }
}

export default function Start(props) {
  const config = useContext(ConfigContext);
  const {
    startPageCaption,
    showSampleDocuments,
    showDocumentList,
  } = config;

  const [ state, dispatch ] = useReducer(startReducer, {});
  const { error } = state;

  return (
    <StartContext.Provider value={{ state, dispatch }}>
      <Container>
        <Header size="large" as="h2" textAlign="center" style={style.header}>
          {startPageCaption ?? <FormattedMessage {...messages.header} />}
        </Header>
        {error && (
          <ErrorMessage error={error} />
        )}
        {showSampleDocuments && (
          <SampleDocuments />
        )}
        {showDocumentList && (
          <DocumentList />
        )}
      </Container>
    </StartContext.Provider>
  );
}
