import { useState, useContext, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { BiometricDataEncryption } from 'biometric-data-encryption';
import SignaturePad from 'signature_pad';

import { DocumentContext } from '../../../../../reducers/Document/documentReducer';
import {
  getBiometricCertificate,
  setBiometricCertificate,
  secureFetch
} from '../../../../../libs/utils';

import clientService from '../../../../../services/client';

import messages from '../../messages';
import { ConfigContext } from '../../../../../reducers/configContext';
import { useDidMountEffect } from '../../../../../libs/hooks.js';

const signaturePadProps = {
  velocityFilterWeight: 0.7,
  minWidth: 0.5,
  maxWidth: 2.5,
  dotSize: 1.5,
  penColor: 'black',
  backgroundColor: 'rgba(0,0,0,0)',
  onEnd: () => {},
  onBegin: () => {},
  minDistance: 5,
  throttle: 16,
  allowedInputs: ["pen", "touch", "mouse"]
}

export default function SignatureArea() {

  let canvasRef = useRef(null);

  const { state, dispatch } = useContext(DocumentContext);
  const { displayOptions, document} = state;

  const config = useContext(ConfigContext);

  const {
    currentSignature,
    zoom,
  } = displayOptions;

  const {
    documentHash,
    biometricCertIssuer,
    biometricCertSerial,
  } = currentSignature;

  const {
    workflowId,
    isDemo,
  } = document;

  const { allowedInputs } = config;
  const [ hasSignature, setHasSignature ] = useState(false);
  const { position, fieldId, signed } = currentSignature;
  const { x, y, width, height } = position;

  const biometricDataEncryption = isDemo ? null : new BiometricDataEncryption();
  const [ encryption ] = useState(biometricDataEncryption);
  const [ signaturePad, setSignaturePad ] = useState(null);

  useDidMountEffect(async () => {
    let biometricCertificate = getBiometricCertificate();

    if (!biometricCertificate ||
        biometricCertificate.issuer !== biometricCertIssuer ||
        biometricCertificate.serial !== biometricCertSerial) {

      await secureFetch(
        () => clientService.getEncryptionCertificate(),
        (response) => {
          biometricCertificate = response.biometricCertificate;
          setBiometricCertificate(biometricCertificate);
        },
        (error) => dispatch({
          type: 'setErrorMessage',
          error,
        }),
        'Nem sikerült lekérdezni a biometrikus tanúsítványt'
      );
    }

    let biometricCertData = undefined;
    let isCertificate = true;
    if (biometricCertificate) {
      biometricCertData = biometricCertificate.certificate !== undefined
        ? biometricCertificate.certificate
        : biometricCertificate.data;
        isCertificate = biometricCertificate.isCertificate ?? true;
    }

    encryption?.init(biometricCertData, isCertificate, documentHash);

    const pad = new SignaturePad(
      canvasRef.current, {
      ...signaturePadProps,
      allowedInputs,
      onBegin: event => setHasSignature(true),
      onData: event => encryption?.addDataPoint(event)
    });

    setSignaturePad(pad);
  });

  useEffect(() => {
    if (canvasRef.current && !signed) {
      const element = canvasRef.current;
      scrollIntoView(element, {
        behavior: 'auto',
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'center',
      });
    }
  }, [currentSignature, signed]);

  const clearSignature = async () => {
    signaturePad?.clear();
    await encryption?.clear();
    setHasSignature(false);
  }

  const cancelSignature = async () => {
    await clearSignature();
    dispatch({ type: 'cancelSignature' });
  }

  const finishSignature = async () => {
    let biometricData = isDemo ? "" : await encryption?.finalize();
    let signatureImage = signaturePad.toDataURL('image/png').split(',')[1];
    await secureFetch(
      () => clientService.finishSignature(workflowId, {
        fieldId,
        biometricData,
        signatureImage,
        biometricCertIssuer,
        biometricCertSerial,
      }),
      (response) => {
        dispatch({ type: 'finishSignature' });
      },
      (error) => dispatch({
        type: 'setErrorMessage',
        error,
      }),
      'Nem sikerült elmenteni az aláírást'
    );
  }

  return (<div
      id="signatureContainer"
      style={{
        textAlign: 'center',
        position: 'absolute',
        overflow: 'visible',
        backgroundSize: 'contain',
        zIndex: 1100,
        left: x * zoom,
        top: y * zoom,
      }}
     >
      <div
        style={{
          backgroundColor: '#ffffff',
          border: '1px solid #000000',
          marginBottom: '1em'
        }}
      >
        <canvas
          ref={canvasRef}
          width={width * zoom}
          height={height * zoom}
        />
      </div>
      <Button
        icon="cancel"
        id="cancelSignature"
        onClick={cancelSignature}
        onKeyDown={cancelSignature}
        content=""
      >
        <Icon name="cancel"/>
        <FormattedMessage {...messages.cancel} />
      </Button>
      {hasSignature && (
        <>
          <Button
            id="clearSignature"
            onClick={clearSignature}
            onKeyDown={clearSignature}
          >
            <Icon name="redo"/>
            <FormattedMessage {...messages.restart} />
          </Button>
          <Button
            id="finishSignature"
            onClick={finishSignature}
            onKeyDown={finishSignature}
          >
            <Icon name="check"/>
            <FormattedMessage {...messages.finish} />
          </Button>
        </>
      )}
    </div>
  );
}
