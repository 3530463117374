import { createContext } from 'react';

export const startReducer = (state, action) => {
  switch (action.type) {
    case 'setErrorMessage': {
      const { error } = action;

      return {
        ...state,
        error
      }
    }

    default:
      return state;
  }
};

export const StartContext = createContext();
