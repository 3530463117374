import { useRef, useEffect } from 'react';

export function useDidMountEffect(func, deps) {
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      func();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
