import axios from 'axios';
import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/Demo/`

const getSampleDocuments = () => {
  return axios.get(
    `${API_URL}getSampleDocuments`,
    getAxiosConfig())
  .then((response) => {
    return response.data;
  });
};

const startSampleDocument = (sampleDocumentPath) => {
  return axios.post(
    `${API_URL}startSampleDocument`,
    {
      sampleDocumentPath
    },
    getAxiosConfig())
  .then((response) => {
    return response.data;
  });
};

const services = {
  getSampleDocuments,
  startSampleDocument
}

export default services;
