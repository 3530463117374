import { useContext } from 'react';
import { Header, Button, Grid, Container} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { DocumentContext } from '../../../../reducers/Document/documentReducer';
import { ConfigContext } from '../../../../reducers/configContext';
import ErrorMessage from '../../../../components/ErrorMessage';

import messages from '../messages';

const style = {
  header: {
    margin: '1.5em 0em',
  }
}

export default function Captions() {
  const { state } = useContext(DocumentContext);
  const { displayOptions } = state;
  const config = useContext(ConfigContext);

  const { lastAction, error } = displayOptions;
  const { showDocumentList, showSampleDocuments } = config;

  const hasStartPage = showDocumentList || showSampleDocuments;

  let caption = null;

  switch(lastAction) {
    case 'terminate':
      caption = messages.terminated;
      break;
    case 'finalize':
      caption = messages.finalized;
      break;
    case 'close':
      caption = messages.closed;
      break;
    default:
      caption = "";
      break;
  }

  const close = () => {
    window.close();
  }

  return (
    <Container>
      {error && (<ErrorMessage error={error}/>)}
      <Header size="large" as="h2" textAlign="center" style={style.header}>
        <FormattedMessage {...caption}/>
      </Header>
      <Grid centered>
        <Grid.Row>
          {hasStartPage
            ? (
              <Link to="/">
                <Button size="large" id="backCaption">
                  <FormattedMessage {...messages.back} />
                </Button>
              </Link>
              )
            : (
              <Button size="large" id="closeCaption" onClick={() => close()}>
                <FormattedMessage {...messages.close} />
              </Button>
            )}

        </Grid.Row>
      </Grid>
    </Container>
  );
}
