import { createContext } from 'react';

const getDefaultZoom = (pageSize, windowWidth) => {
  let zoom = 1.0;
  if (pageSize !== undefined) {
    const maxWidth = Math.min(
      windowWidth - 32,
      768,
    );
    zoom = maxWidth / pageSize[1].width;
  }
  return zoom;
}

const getSignatureZoom = (currentSignature, windowWidth) => {
  const maxWidth = Math.min(
    windowWidth - 64,
    400,
  );
  return maxWidth / currentSignature.position.width;
}

export const documentReducer = (state, action) => {
  switch (action.type) {
    case 'setImage': {
      const { image } = action;
      const { displayOptions } = state;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          image,
        },
      }
    }

    case 'loadDocumentData': {
      const { data } = action;
      const {
        signatureFields,
        pageSizes,
        isDemo,
        unsignedSignatureFields,
      } = data;

      const { document, displayOptions } = state;
      const { originallyUnsignedFields, workflowId } = document;
      const {
        page,
        currentSignature,
        windowWidth,
      } = displayOptions;

      const pageSizeArray = Object.entries(pageSizes);

      return {
        displayOptions: {
          ...displayOptions,
          areFieldsEditable: !signatureFields.some(s => s.signed),
          zoom: currentSignature
            ? getSignatureZoom(currentSignature, windowWidth)
            : getDefaultZoom(pageSizeArray[page - 1], windowWidth),
        },
        document: {
          ...data,
          workflowId,
          pageSizes: pageSizeArray,
          originallyUnsignedFields:
            originallyUnsignedFields ??
            unsignedSignatureFields ??
            signatureFields.length,
          isDemo: isDemo ?? false,
        },
      }
    }

    case 'finalizeDocumentStarted': {
      const { displayOptions } = state;
      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          finalizeInProgress: true,
        },
      }
    }

    case 'finalizeDocumentStopped': {
      const { displayOptions } = state;
      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          finalizeInProgress: false,
        },
      }
    }

    case 'finalizeDocument': {
      const { document, displayOptions } = state;
      return {
        document: {
          ...document,
          finalized: true,
        },
        displayOptions: {
          ...displayOptions,
          lastAction: 'finalize',
        },
      }
    }

    case 'terminateDocument': {
      const { displayOptions } = state;
      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          lastAction: 'terminate',
        },
      }
    }

    case 'fieldsFilled': {
      const { displayOptions } = state;
      let { changeNumber } = displayOptions;
      changeNumber++;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          changeNumber,
        },
      }
    }

    case 'closeDocument': {
      const { displayOptions } = state;
      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          lastAction: 'close',
        },
      }
    }

    case 'startSignature': {
      const {
        documentHash,
        fieldId,
        biometricCertIssuer,
        biometricCertSerial,
      } = action;

      const { document, displayOptions } = state;
      const { signatureFields } = document;
      const { windowWidth } = displayOptions;

      const currentSignature = signatureFields
        .find(field => field.fieldId === fieldId);

      const zoom = getSignatureZoom(currentSignature, windowWidth);

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          zoom,
          currentSignature: {
            ...currentSignature,
            documentHash,
            biometricCertIssuer,
            biometricCertSerial,
          },
        }
      }
    }

    case 'cancelSignature': {
      const { document, displayOptions } = state;
      const { pageSizes } = document;
      const { page, windowWidth } = displayOptions;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          currentSignature: null,
          zoom: getDefaultZoom(pageSizes[page - 1], windowWidth),
        },
      }
    }

    case 'finishSignature': {
      const { document, displayOptions } = state;
      const { pageSizes } = document;
      let { changeNumber, page, windowWidth } = displayOptions;

      changeNumber++;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          currentSignature: null,
          zoom: getDefaultZoom(pageSizes[page - 1], windowWidth),
          changeNumber,
        }
      }
    }

    case 'setTextFieldValue': {
      const { fieldId, value } = action;

      const { document } = state;
      let { textBoxFields } = document;

      const fieldIdx = textBoxFields.findIndex(f => f.fieldId === fieldId);
      const textBoxField = {
        ...textBoxFields[fieldIdx],
        value
      };
      textBoxFields[fieldIdx] = textBoxField;

      return {
        ...state,
        document: {
          ...document,
          textBoxFields,
        }
      }
    }

    case 'checkField': {
      const { fieldId } = action;

      const { document } = state;
      let { checkboxFields } = document;

      const fieldIdx = checkboxFields.findIndex(f => f.fieldId === fieldId);
      let checkboxField = checkboxFields[fieldIdx];
      checkboxField.checked = !checkboxField.checked;

      return {
        ...state,
        document: {
          ...document,
          checkboxFields,
        }
      }
    }

    case 'checkCheckboxGroupField': {
      const { groupId, fieldId } = action;

      const { document } = state;
      let { checkboxGroupFields } = document;

      const groupIdx = checkboxGroupFields
        .findIndex(f => f.groupId === groupId);
      let checkboxGroup = checkboxGroupFields[groupIdx];

      const { multiple, fields } = checkboxGroup;
      let newFields = [...fields];

      if (multiple) {
        let checkboxFieldIdx = newFields.findIndex(f => f.fieldId === fieldId);
        let checkboxField = {
          ...newFields[checkboxFieldIdx],
        };
        checkboxField.checked = !checkboxField.checked;
        newFields[checkboxFieldIdx] = checkboxField;
      } else {
        for(let i = 0; i < newFields.length; i++) {
          newFields[i].checked = newFields[i].fieldId === fieldId;
        }
      }

      checkboxGroup = {
        ...checkboxGroup,
        fields: newFields
      }

      let newGroups = [...checkboxGroupFields]
      newGroups[groupIdx] = checkboxGroup;

      return {
        ...state,
        document: {
          ...document,
          checkboxGroupFields: newGroups,
        },
      }
    }

    case 'zoomIn': {
      const { displayOptions } = state;
      const { zoom } = displayOptions;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          zoom: zoom * 1.25,
          image: null,
        },
      }
    }

    case 'zoomOut': {
      const { displayOptions } = state;
      const { zoom } = displayOptions;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          zoom: zoom * 0.8,
          image: null,
        },
      }
    }

    case 'firstPage':
    case 'prevPage':
    case 'nextPage':
    case 'lastPage': {
      const { displayOptions, document } = state
      let { page, image, windowWidth } = displayOptions;
      const { pageSizes } = document;

      switch(action.type) {
        case 'firstPage':
          page = 1;
          break;
        case 'prevPage':
          page--;
          break;
        case 'nextPage':
          page++;
          break;
        case 'lastPage':
          page = pageSizes.length;
          break;
        default:
          break;
      }
      if (page < 1) {
        page = 1;
      }
      if (page > pageSizes.length) {
        page = pageSizes.length;
      }
      image = null;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          zoom: getDefaultZoom(pageSizes[page - 1], windowWidth),
          image,
          page,
        },
      }
    }

    case 'setErrorMessage': {
      const { displayOptions } = state;
      const { error } = action;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          error,
        },
      }
    }

    case 'downloadDocument': {
      const { document, fileName } = action;

      return {
        ...state,
        downloadedDocument: {
          document,
          fileName,
        },
      }
    }

    case 'windowResize': {
      const { displayOptions, document } = state
      const { page, currentSignature } = displayOptions;
      const { pageSizes } = document;
      const { offsetWidth } = action;

      return {
        ...state,
        displayOptions: {
          ...displayOptions,
          zoom: currentSignature
            ?  getSignatureZoom(currentSignature, offsetWidth)
            : getDefaultZoom(pageSizes[page - 1], offsetWidth),
          windowWidth: offsetWidth,
        },
      }
    }

    default:
      return state;
  }
};

export const initialDocumentState = {
  displayOptions: {
    page: 1,
    zoom: 0.64,
    windowWidth: 1024,
    currentSignature: null,
    changeNumber: 0,
    image: null,
    lastAction: null,
    finalizeInProgress: false,
    error: null,
  },
  document: {
    pageSizes: [],
    signatureFields: [],
    checkboxFields: [],
    checkboxGroupFields: [],
    textFields: [],
    workflowId: null,
    finalized: false,
  },
  downloadedDocument: null,
}

export const DocumentContext = createContext();
