import { useRef, useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';

import  {
  DocumentContext,
  documentReducer,
  initialDocumentState
} from '../../../reducers/Document/documentReducer';

import Captions from './Captions';
import DocumentView from './DocumentView';

export default function Document(props) {
  const { workflowId } = props;

  const { document } = initialDocumentState;

  const documentState = {
    ...initialDocumentState,
    document: {
      ...document,
      workflowId,
    },
  };

  const [ state, dispatch ] = useReducer(documentReducer, documentState);
  const { displayOptions } = state;
  const { lastAction } = displayOptions;

  const appWrapperRef = useRef(null);

  const updateDimensions = () => {
    if (appWrapperRef.current) {
      dispatch({
        type: 'windowResize',
        offsetWidth: appWrapperRef.current.offsetWidth,
      });
    }
  }

  useEffect(updateDimensions, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(updateDimensions, 100);
    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  });

  return (
      (
        <DocumentContext.Provider value={{ state, dispatch }}>
          <div ref={appWrapperRef}>
            {lastAction
              ? <Captions/>
              : <DocumentView/>
            }
          </div>
        </DocumentContext.Provider>
      )
  );
}
