export async function secureFetch (
  fetchResult,
  processResult,
  handleError,
  defaultErrorMessage) {
    try {
      handleError('');
      const result = await fetchResult();
      if (result.errorCode === 0) {
        return processResult(result);
      } else {
        const {
          errorCode,
          errorMessage
        } = result;
        handleError({
          errorCode,
          errorMessage: errorMessage ?? defaultErrorMessage
        });
      }
    } catch {
      handleError(defaultErrorMessage);
    }
}

export function getBiometricCertificate() {
  const biometricCert = JSON.parse(sessionStorage.getItem('cert'));
  if (biometricCert) {
    return biometricCert.certificate !== undefined
      ? biometricCert.certificate
      : biometricCert.data;
  }
  return null;
}

export function setBiometricCertificate(biometricCertificate) {
  sessionStorage.setItem(
    'cert',
    JSON.stringify(biometricCertificate),
  );
}

export function omitNull (obj) {
  const keys = Object.keys(obj);
  const result = {};
  for (const key of keys) {
    const value = obj[key];
    if (value !== null) {
      result[key] = value;
    }
  }
  return result;
}
