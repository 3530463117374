const BASE_URL = '';

const authHeader = () => {
  const token = sessionStorage.getItem('token');

  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}

const getAxiosConfig = () => {
  return {
    headers: authHeader(),
    validateStatus: function (status) {
      return status >= 200 && status < 500;
    }
  }
}

export {
  BASE_URL,
  getAxiosConfig
};
