import { Container, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const style = {
  header: {
    marginTop: '3em',
  }
}

export default function NotFound() {
  return (
    <Container>
      <Header size="large" as="h2" textAlign="center" style={style.header}>
        <FormattedMessage {...messages.header} />
      </Header>
    </Container>
  );
}
