import { defineMessages } from 'react-intl';

export default defineMessages({
  finalize: 'Finalize',
  terminate: 'Terminate',
  back: 'Back',
  close: 'Close',
  download: 'Download',
  loading: 'Loading...',
  restart: 'Restart',
  cancel: 'Cancel',
  finish: 'Finish',
  terminated: 'Document terminated',
  finalized: 'Document finalized',
  closed: 'Document closed',
  signatureFields: 'Number of signatures: {unsignedFields}',
  downloadDescription: 'Your download will start shortly, if not click on the link:',
  documentFinalized: 'The document has been finalized. You can now close this page.',
  firstPage: 'First page',
  nextPage: 'Next page',
  lastPage: 'Last page',
  previousPage: 'Previous page',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  downloadTooltip: 'Download the PDF document',
  finalizeTooltip: 'Finalize the document',
  closeTooltip: 'Close the document',
  backTooltip: 'Back',
  terminateTooltip: 'Terminate the document',
  unsignedFields: 'Remaining signatures:',
  helpTooltip: `
    <p>
      Welcome to the SignoWise signing page!
    </p>`,
});
