import axios from 'axios';
import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/Client/`

const getConfiguration = () => {
  return axios.get(
    `${API_URL}GetConfiguration`,
      getAxiosConfig())
  .then((response) => {
    return response.data;
  });
};

const getDocumentData = (workflowId) => {
  return axios
    .post(
      `${API_URL}GetDocumentData`,
      {
        workflowId
      },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
}

const getImage = (workflowId, page, zoom) => {
  return axios
    .get(
      `${API_URL}GetImage?workflowId=${workflowId}&pageNumber=${page}&scale=${zoom}`,
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
};

const downloadDocument = (workflowId) => {
  return axios
    .post(
      `${API_URL}DownloadDocument`,
      {
        workflowId
      },
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
};

const deleteDocument = (workflowId) => {
  return axios
    .post(
      `${API_URL}DeleteDocument`,
      {
        workflowId
      },
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
};

const finalizeDocument = (workflowId, closeDocument) => {
  return axios
    .post(
      `${API_URL}FinalizeDocument`,
      {
        workflowId,
        closeDocument
      },
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
};

const terminateDocument = (workflowId) => {
  return axios
    .post(
      `${API_URL}TerminateDocument`,
      {
        workflowId
      },
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
};

const fillFields = (workflowId, checkboxFields, checkboxGroups, textFields) => {
  return axios
    .post(
      `${API_URL}FillFields`,
      {
        workflowId,
        checkboxFields,
        checkboxGroups,
        textFields
      },
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
};

const getEncryptionCertificate = () => {
  return axios
    .get(
      `${API_URL}GetEncryptionCertificate`,
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
}

const startSignature = (workflowId, fieldId) => {
  return axios
    .post(
      `${API_URL}StartSignature`,
      {
        workflowId,
        fieldId
      },
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
}

const finishSignature = (workflowId, signatureData) => {
  return axios
    .post(
      `${API_URL}FinishSignature`,
      {
        workflowId,
        ...signatureData
      },
      getAxiosConfig())
    .then((response) => {
        return response.data;
    });
}

const services = {
  getConfiguration,
  getDocumentData,
  getImage,
  deleteDocument,
  finalizeDocument,
  terminateDocument,
  downloadDocument,
  fillFields,
  getEncryptionCertificate,
  startSignature,
  finishSignature
}

export default services;