
import { useContext } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { DocumentContext } from '../../../../../reducers/Document/documentReducer';
import { ConfigContext } from '../../../../../reducers/configContext';
import { secureFetch } from '../../../../../libs/utils';
import clientService from '../../../../../services/client';

import messages from '../../messages';
import { downloadFile } from '../../../../../libs/downloadHelper';

export default function ActionButtons(props) {
  const { state, dispatch } = useContext(DocumentContext);
  const { displayOptions, document } = state;
  const config = useContext(ConfigContext);

  const renderFn = props.renderFn ?? ((item) => <>{item}</>);

  const {
    finalizeInProgress,
    currentSignature,
  } = displayOptions;

  const {
    workflowId,
    signatureFields,
    finalized,
    originallyUnsignedFields,
  } = document;

  const {
    allowEarlyFinalization,
    showDocumentList,
    showSampleDocuments,
    showDownloadButton,
    deleteDocumentOnClose,
    showHelp,
  } = config;

  const displayDownloadButton = finalized && showDownloadButton;

  const displayFinalizeButton =
    !finalized &&
    (
      allowEarlyFinalization ||
      signatureFields.every(s => s.signed)
    );

  const displayTerminateButton = !finalized;
  const unsignedSignatureFields = signatureFields.filter(f => !f.signed).length;

  const disableButtons = currentSignature !== null;

  const noNewSignatures =
    !signatureFields.every(s => s.signed) &&
    unsignedSignatureFields === originallyUnsignedFields;

  const disableFinalizeButton =
    disableButtons ||
    finalizeInProgress ||
    noNewSignatures;

  const hasStartPage = (showDocumentList || showSampleDocuments);

  const displayCloseButton = finalized && !hasStartPage && window.opener;
  const displayBackButton = finalized && hasStartPage;

  const finalize = async () => {
    if (!finalizeInProgress) {
      dispatch({ type: 'finalizeDocumentStarted' });
      await secureFetch(
        () => clientService.finalizeDocument(workflowId),
        (response) => {
          dispatch({ type: 'finalizeDocumentStopped' });
          dispatch({ type: 'finalizeDocument' });
        },
        (error) => {
          if (error) {
            dispatch({ type: 'finalizeDocumentStopped' });
          }
          dispatch({
            type: 'setErrorMessage',
            error,
          });
        },
        'Nem sikerült lekérdezni az aláírást'
      );
    }
  };

  const terminate = async () => {
    await secureFetch(
      () => clientService.terminateDocument(workflowId),
      (response) => {
        dispatch({ type: 'terminateDocument' });
      },
      (error) => {
        dispatch({
          type: 'setErrorMessage',
          error,
        });
      },
      'Nem sikerült lekérdezni az aláírást'
    );
  }

  const close = async () => {
    if (deleteDocumentOnClose) {
      await secureFetch(
        () => clientService.deleteDocument(workflowId),
        (response) => dispatch({ type: 'closeDocument' }),
        (error) => dispatch({ type: 'setErrorMessage', error}),
        'Nem sikerült törölni a documentumot'
      );
    } else {
      window.close();
    }
  }

  const download = async () => {
    await secureFetch(
      () => clientService.downloadDocument(workflowId),
      (response) => {
        let { fileName, document } = response;
        fileName = fileName ?? `${workflowId}.pdf`;

        downloadFile(fileName, document);

        dispatch({
          type: 'downloadDocument',
          document,
          fileName,
        });
      },
      (error) => dispatch({ type: 'setErrorMessage', error}),
      'Nem sikerült letölteni a documentumot'
    );

  }

  return (
    <>
      {displayFinalizeButton && (
        <FormattedMessage {...messages.finalizeTooltip}>
          {txt => renderFn(
            <Popup content={txt} trigger={
              <Button id="finalize" onClick={finalize} disabled={disableFinalizeButton}>
                <FormattedMessage {...messages.finalize}/>
              </Button>
            }/>
          )}
        </FormattedMessage>
      )}
      {displayTerminateButton && (
        <FormattedMessage {...messages.terminateTooltip}>
          {txt => renderFn(
            <Popup content={txt} trigger={
              <Button id="terminate" onClick={terminate} disabled={disableButtons}>
                <FormattedMessage {...messages.terminate}/>
              </Button>
            }/>
          )}
        </FormattedMessage>
      )}
      {displayBackButton && (
        <FormattedMessage {...messages.backTooltip}>
          {txt => renderFn(
            <Popup content={txt} trigger={
              <Link to="/">
                <Button id="back" disabled={disableButtons}>
                  <FormattedMessage {...messages.back}/>
                </Button>
              </Link>
            }/>
          )}
        </FormattedMessage>
      )}
      {displayCloseButton && (
        <FormattedMessage {...messages.closeTooltip}>
          {txt => renderFn(
            <Popup content={txt} trigger={
              <Button id="close" onClick={close} disabled={disableButtons}>
                <FormattedMessage {...messages.close}/>
              </Button>
            }/>
          )}
        </FormattedMessage>
      )}
      {displayDownloadButton && (
        <FormattedMessage {...messages.downloadTooltip}>
          {txt => renderFn(
            <Popup content={txt} trigger={
              <Button id="download" onClick={download} disabled={disableButtons}>
                <FormattedMessage {...messages.download}/>
              </Button>
            }/>
          )}
        </FormattedMessage>
      )}
      {showHelp && (
        <FormattedMessage
          {...messages.helpTooltip}
          values={{
            p: msg => <p>{msg}</p>
          }}
        >
          {txt => renderFn(
            <Popup content={
              <div style={{maxWidth: '600px'}}>
                {txt}
              </div>}
              flowing
              position="bottom right"
              on="click"
              trigger={
                <Button id="help" icon="help" disabled={disableButtons}/>
              }
            />
          )}
        </FormattedMessage>
      )}
    </>
  );
}
